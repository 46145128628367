<template>
    <div class="customer-files">
        <b-card
            :header="getFolderName()"
            header-tag="strong"
        >
            <directory-list
                :directory="directory"
                :documents="this.documents"
                :directories="this.directories"
                :action="action"
                :customer="customer"
                @deleteDirectory="deleteDirectory"
                @deleteDocument="deleteDocument"
                @clearAction="clearAction"
                @clearActionOnly="clearActionOnly"
                @updateDirId="updateDirId"
                @editDirectory="editDirectory"
                @editDocument = "editDocument"
            />
        </b-card>
        <div v-if="$helper.userCapability('update',$route)" class="buttons">
            <button class="btn btn-primary" @click="action = 'addDirectory'">
                {{ $t('forms.addDirectory') }}
            </button>
            <button class="btn btn-gray" @click="action = 'addDocument'">
                {{ $t('forms.addDocument') }}
            </button>
        </div>
    </div>
</template>

<script>
import DirectoryList from "@/components/Files/DirectoryList";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: "Documents",
    components: {
        DirectoryList,
    },
    props: {
        customer: {
            type: Object, default() {
            }
        }
    },
    mixins: [ResourceUtils],
    data() {
        return {
            directory: null,
            dirId: null,
            directories: [],
            documents: [],
            action: null,
        };
    },
    created() {
        this.loadDirectories();
        this.loadDocuments();
    },
    computed: {},
    methods: {
        loadDirectories() {
            if (this.dirId) {
                this.$Directories.getResource({id: this.dirId, customer: this.customer.id}).then((response) => {
                    this.directory = response.data;
                });
                this.$Directories.getCollection({
                    params: {
                        'parent': this.dirId,
                        customer: this.customer.id,
                        'order[name]': 'ASC',
                        'pagination': false
                    }
                }).then((response) => {
                    this.directories = response.data["hydra:member"];
                });
            } else {
                this.$Directories.getCollection({
                    params: {
                        'exists[parent]': false,
                        customer: this.customer.id,
                        'order[name]': 'ASC'
                    }
                }).then((response) => {
                    this.directories = response.data["hydra:member"];
                });
            }
        },
        loadDocuments() {
            if (this.dirId) {
                this.$Documents.getCollection({
                    params: {
                        'directory': this.dirId,
                        customer: this.customer.id,
                        'pagination': false
                    }
                }).then((response) => {
                    this.documents = response.data["hydra:member"];
                });
            } else {
                this.$Documents.getCollection({
                    params: {
                        'exists[directory]': false,
                        customer: this.customer.id
                    }
                }).then((response) => {
                    this.documents = response.data["hydra:member"];
                });
            }
        },
        updateDirId(data) {
            this.dirId = data
            if (this.dirId === null) {
                this.directory = null
            }
            this.directory = null
            this.loadDirectories()
            this.loadDocuments()
        },
        editDirectory() {
            this.action = 'editDirectory'
        },
        editDocument() {
            this.action = 'editDocument'
        },
        getFolderName() {
            if (this.directory) {
                return this.directory.name
            }
            return this.$t('forms.root')
        },
        deleteDirectory(url) {
            this.deleteByUrl(this.$Files, url, this.$t("messages.directoryDeleted"), null, this.loadDirectories, null);
        },
        deleteDocument(url) {
            this.deleteByUrl(this.$Files, url, this.$t("messages.documentDeleted"), null, this.loadDocuments, null);
        },
        submitPressed() {
            this.action = 'addDirectory'
        },
        secondaryPressed() {
            this.action = 'addDocument'
        },
        clearAction() {
            this.loadDirectories()
            this.loadDocuments()
            this.action = null
        },
        clearActionOnly() {
            this.action = null
        }
    },
};
</script>
