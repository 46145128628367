<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols-lg="6">
                    <b-form-group
                        :class="{ row, error: v$.customer.customer.$errors.length }"
                        :label="$t('forms.name')"
                        label-for="additionalName"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="customer.customer"
                            id="additionalName"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.customer.customer.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.customer.email.$errors.length }"
                        :label="$t('forms.email')"
                        label-for="email"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input v-model="customer.email" id="email"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.customer.email.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.active')"
                        label-for="h-active"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-checkbox v-model="customer.enabled" id="h-active" switch/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.contracted')"
                        label-for="h-contracted"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-checkbox v-model="customer.customerData.contracted" id="h-contracted" switch/>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.customer.branches.$errors.length }"
                        :label="$t('forms.branch')"
                        label-for="branchSelect"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="branchSelect"
                            v-model="customer.branches"
                            :options="branches"
                            taggable
                            multiple
                            push-tags
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.customer.branches.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Address / Registered seat"
                        label-for="registeredSeat"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea
                            v-model="customer.customerData.registeredSeat"
                            id="registeredSeat"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="VAT / Registration number"
                        label-for="vat"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input v-model="customer.customerData.vat" id="vat"/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Contact person for contractual matters / Contract notification"
                        label-for="contactPerson"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.contactPerson" id="contactPerson"/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Contract valid from"
                        label-for="h-start"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="start"
                            v-model="customer.customerData.contractValidFrom"
                            :enableTimePicker="false"
                            format="dd.MM.yyyy"
                            class="form-input"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Contract valid until"
                        label-for="h-start"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="start"
                            v-model="customer.customerData.contractValidUntil"
                            :enableTimePicker="false"
                            format="dd.MM.yyyy"
                            class="form-input"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols-lg="6">
                    <b-form-group
                        class="row"
                        :label="$t('forms.settlementOfAccounts')"
                        label-for="settlementOfAccounts"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea
                            v-model="customer.customerData.settlementOfAccounts"
                            id="settlementOfAccounts"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Invoicing address"
                        label-for="address"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="customer.address.streetAddress"
                            id="address"
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="E-invoice to be sent"
                        label-for="eInvoice"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.eInvoice" id="eInvoice"/>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        label="ORG invoice to be shipped"
                        label-for="orgInvoice"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.orgInvoice" id="orgInvoice"/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Remarks / Notes"
                        label-for="purchaser"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="customer.customerData.notes" id="purchaser"/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Notification emails CWO"
                        label-for="notificationEmails"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="notificationEmails"
                            v-model="customer.notificationEmails"
                            taggable
                            multiple
                            push-tags
                        />
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Notification emails OCR"
                        label-for="notificationEmailsOcr"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="notificationEmailsOcr"
                            v-model="customer.notificationEmailsOcr"
                            taggable
                            multiple
                            push-tags
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from '@vuepic/vue-datepicker';
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect,
    },
    props: {
        customerObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getResourceList(this.$Branches, this.branches);
        if ("branches" in this.customer && this.customer.branches) {
            this.customer.branches = this.selected(this.customer.branches)
        } else {
            this.customer.branches = []
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            customer: {
                branches: {required},
                customer: {required},
                email: {required, email},
            },
        };
    },
    data() {
        return {
            customer: this.customerObject,
            branches: [],
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                const body = JSON.parse(JSON.stringify(this.customer));
                delete body.stations
                body.branches = this.customer.branches.map(function (branch) {
                    return branch.id
                });
                if (this.action === "add") {
                    this.create(
                        this.$Users,
                        body,
                        this.$t("messages.customerCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(
                        this.$Users,
                        this.customer.id,
                        body,
                        this.$t("messages.customerUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        selected(values) {
            const list = [];
            values.forEach(element => {
                list.push({
                    label: `${element.name}`,
                    id: element['@id'],
                })
            })
            return list;
        },
        success() {
            this.$router.push(`/customers`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

