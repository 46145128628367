<template>
    <div class="employee-files-form">
        <b-card
            v-for="(item, index) in fileTags"
            :key="index"
            :header="$t('forms.' + item)"
            header-tag="header"
        >
            <files-list :files="getFilesByTags([item])" @deleteFile="deleteFile"/>
            <document-upload v-if="canUpload && ($helper.userCapability('update',$route) || $helper.userCapability('create',$route))" :simple="true" :tag="item"
                             @fileUploaded="setFile" label="Add file"/>
        </b-card>
    </div>
</template>

<script>
import FilesList from "./FilesList.vue";
import DocumentUpload from "@/components/DocumentUpload.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    components: {
        FilesList,
        DocumentUpload,
    },
    props: {
        resource: {type: Object, required: true},
        resourceId: {type: Number, default: 0},
        fileTags: {
            type: Array, default() {
            }
        },
        message: {type: String, default: 'entryUpdated'},
        canUpload: {type: Boolean, default: true}
    },
    mixins: [ResourceUtils],
    data() {
        return {
            files: []
        };
    },
    created() {
        this.loadFiles();
    },
    computed: {},
    methods: {
        loadFiles() {
            this.resource.getResourceFiles({id: this.resourceId, params: {pagination: false}}).then((response) => {
                this.files = response.data["hydra:member"];
            });

        },
        setFile(data) {
            this.files.push(data);
            this.save();
        },
        filesIri() {
            const filesIri = [];
            this.files.forEach((element) => {
                filesIri.push(element["@id"]);
            });
            return filesIri;
        },
        getFilesByTags(tags) {
            const files = [];
            if (!Array.isArray(tags)) {
                tags = [tags];
            }
            this.files.forEach((file) => {
                tags.forEach((tag) => {
                    if (file.tags.includes(tag)) {
                        files.push(file);
                    }
                });
            });
            return files;
        },
        deleteFile(url) {
            this.deleteByUrl(
                this.$Files,
                url,
                this.$t("messages.fileDeleted"),
                null,
                this.loadFiles,
                null
            );
        },
        save() {
            const body = {
                files: this.filesIri(),
            };
            this.update(
                this.resource,
                this.resourceId,
                body,
                this.$t("messages." + this.message),
                null,
                null,
                null
            );
        },
    },
};
</script>

<style>
</style>
